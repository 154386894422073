$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

$small-mobile-screen: 360px;
$medium-mobile-screen: 400px;
$tablet-for-tiles: 570px;

@mixin sm {
  @media (min-width: $screen-sm) {
    @content;
  }
}

@mixin md {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $screen-xl) {
    @content;
  }
}

// outdated responsive mixins. Use mobile-first approach
@mixin mobile-legacy {
  @media (max-width: $screen-md - 1) {
    @content;
  }
}

@mixin tablet-legacy {
  @media (min-width: $screen-md) and (max-width: $screen-lg - 1) {
    @content;
  }
}

@mixin small-desktop-legacy {
  @media (min-width: $screen-lg) and (max-width: $screen-xl - 1) {
    @content;
  }
}

@mixin mobile-or-tablet-legacy {
  @media (max-width: $screen-lg - 1) {
    @content;
  }
}

// we want phones in landscape to show blv and homepage tiles in tablet view (horizontal tiles)
@mixin tile-mobile-legacy {
  @media (max-width: $tablet-for-tiles - 1) {
    @content;
  }
}

@mixin tile-tablet-legacy {
  @media (min-width: $tablet-for-tiles) and (max-width: $screen-lg - 1) {
    @content;
  }
}

@mixin small-mobile-legacy {
  @media (max-width: $small-mobile-screen - 1) {
    @content;
  }
}

@mixin medium-mobile-legacy {
  @media (max-width: $medium-mobile-screen - 1) {
    @content;
  }
}
