@mixin btn(
  $color,
  $background-color,
  $hover-background-color,
  $active-background-color: $hover-background-color,
  $disabled-background-color: lighten($background-color, $state-color-deviation),
  $disabled-border-color: $disabled-background-color,
  $hover-color: #fff,
  $border-color: $color-primary,
  $hover-border-color: $hover-background-color,
  $active-border-color: $hover-border-color,
  $active-color: $color,
  $disabled-color: $color
) {
  display: inline-flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: center;
  justify-content: center;
  transition-property: border-color, background-color, color, box-shadow, fill;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-family: $font-family-base;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: $button-border-radius;
  border-color: $border-color;
  background-color: $background-color;
  color: $color;
  outline: none;

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
  }

  &:hover {
    border-color: $hover-border-color;
    background-color: $hover-background-color;
    color: $hover-color;
  }

  &:focus,
  &:active {
    border-color: $active-border-color;
    background-color: $active-background-color;
    color: $active-color;
  }

  @include focus-visible();

  &:focus-visible {
    background-color: $background-color;
    color: $color;
    border-color: $border-color;
  }

  &[disabled],
  &:hover[disabled],
  &:focus[disabled],
  &.visuallyDisabled {
    pointer-events: none !important;
    transform: none;
    border-color: $disabled-border-color;
    box-shadow: none;
    background-color: $disabled-background-color;
    cursor: not-allowed;
    color: $disabled-color;

    svg {
      pointer-events: none;
    }
  }
}
