@import "~app/styleguide/styles/colors/index";
@import "~app/styleguide/styles/misc/variables";

@mixin link {
  font-weight: 500;
  text-decoration: underline;
  text-decoration-skip-ink: auto;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

@mixin link-inline {
  @include focus-visible-rounded($hasPadding: false);
}

@mixin link-general {
  font-weight: 500;
  color: $link-color;

  &:focus,
  &:hover {
    color: $link-color;
  }

  @include focus-visible-rounded();
}

@mixin link-primary {
  font-weight: 500;
  color: $link-color-primary;

  &:focus,
  &:hover {
    color: $link-color-primary;
  }

  @include focus-visible-rounded();
}

@mixin link-accented {
  font-weight: 500;
  color: $link-color;
  transition: color $link-transition-duration $transition-timing-function;

  &:focus,
  &:hover {
    color: $link-color-primary;
  }

  @include focus-visible-rounded();
}

@mixin link-action {
  font-weight: 500;
  color: $link-color;
  border-radius: $button-border-radius;
  transition: background-color $link-transition-duration $transition-timing-function;
  margin-inline: -$gap;
  padding: $gap-small $gap;

  &:focus,
  &:hover {
    color: $link-color;
  }

  &:hover {
    background-color: $background-light;
  }

  &:active {
    background-color: $background-normal;
  }

  &:focus-visible {
    background-color: transparent;
  }

  @include focus-visible();
}
