/* 
This file should only contain colors from Color Palette
*/

@import "~app/styleguide/styles/colors/palette";

// base Uicolor
$white: $color-shades-0;
$black: $color-shades-100;

$color-general: $color-neutral-800;

$color-primary-lightest: $color-primary-50;
$color-primary-lighter: $color-primary-100;
$color-primary: $color-primary-500;
$color-primary-hover: $color-primary-600;
$color-primary-active: $color-primary-700;

$color-neutral-lightest: $color-neutral-50;
$color-neutral-lighter: $color-neutral-100;
$color-neutral-light: $color-neutral-400;
$color-neutral: $color-neutral-500;
$color-neutral-dark: $color-neutral-600;
$color-neutral-darker: $color-neutral-700;
$color-neutral-darkest: $color-neutral-900;

$color-error: $color-error-500;
$color-attention: $color-error-500;
$color-warning: $color-warning-300;

// verification
$color-verification-success: $color-success-500;
$color-verification-partial: $color-warning-300;
$color-verification-progress: $color-secondary-500;
$color-verification-error: $color-error;

// socials
$color-airbnb-pink: #f95f62;
$color-bookingcom-blue: #0c3b7c;
$color-vrbo-blue: #245abc;

/*
OLD colors start here
*/
// Base Uicolor
$orange: #f5764f;

$vrbo-blue-light: #eff5f7;

$bookingcom-calendar: #03cccb;
$beige-lighter: #fff8f3;
$beige-light: #fff8eb;
$beige: #f9ebd3;
$beige-dark: #fef8ed;

$green-very-dark: #5eaa66;
$green-darker: #00a72d;
$green-darkest: #235c47; // web vitals - accessibility
$green: #2dd886;
$green-light: #6ee7b7;
$green-lightest: #f2fff4;
$hichee-calendar: #576382;
$teal: #009897;
$teal-lighter: #c7dfdf;
$teal-lightest: #ebf9f9;
$pink: #fff4f2;

$airbnb-pink-light: #fff8eb;
$airbnb-pink-dark: #f95f62;
$red-light: #feefef;
$red: #ff000f;
$danger: #ef4444;
$red-dark: #cd3301;
$red-darker: #e23636;
$grayish-blue: #aabbc2;
$light-sea-blue: #7eaab7;
$light-pink: #fffafa;

$anti-flash-white: #eff5f7;

$lighter-gray: #7e8388;

$gray-darker: #ddd;
$gray-light: #777;
$gray-lighter: #b8b8b8;
$gray-more-lighter: #aaa;
$gray-medium-shade: #7e8388;
$gray-lightest: #e8e8e8;
$gray-extra-light: #f5f5f5;
$gray-extra-light-shade: #e3e3e3;
$gray-lighter-shade: #d6dfe3;
$gray-light-shade: #e3eaec;
$gray-lightest-shade: #b8c7ce;
$gray-very-light: #eff2f4;
$gray-regular-light: #f3f4f4;
$darker-gray-shadow: rgba(0, 0, 0, 0.1);
$gray-shadow: rgba(0, 0, 0, 0.25);
$dark-gray-shadow: rgba(0, 0, 0, 0.5);
$card-shadow: rgba(43, 72, 101, 0.24);
$silver-darker: #a6b0b8;
$silver-dark: #7f92a9;
$silver: #eff5f7;
$silver-light: #ebebeb;

$white-shadow: rgba(255, 255, 255, 0.25);
$white-shadow-darker: rgba(255, 255, 255, 0.17);
$white-shadow-lighter: rgba(255, 255, 255, 0.5);

$text-blue-lighter-shade: #8597a8;
$text-blue-extra-light: #8d9dad;
$text-black-light: #939596;
$blue-background-light: #f1f5f7;
$blue-background: #ebf9f9;
$gray-transparent-background: rgba(42, 43, 43, 0.5);
$yellow: #ffbd42;
$yellow-light: #f8e53e;
$yellow-dark: #fcd34d;
$bright-yellow: #ff0;
$mustard-yellow: #ffcd00;
$blue-lighter-shade: #a1b1b9;

/* stylelint-disable declaration-colon-space-after */

@import "../../../../styleguide/styles/colors/index";

// Only bootstrap variables should stay here
$gray-base: #000 !default;

$brand-primary: $color-primary;
$brand-primary-light: $color-primary-50;
$brand-success: $color-success-500;
$brand-info: $color-success-500;
$brand-warning: $color-warning-500;
$brand-danger: $color-error;
$brand-general: $color-neutral;
$brand-light-extra: $color-neutral-50;

// Message
$message-alert: $beige;
$message-info: $color-neutral-lighter;

// Common
$body-bg: $white;
$text-color: $color-general;

$link-black: #000;
$link-primary: $color-primary;
$link-general: $color-primary;
$link-color: $color-primary;
$link-hover-color: $color-primary-hover;
$link-hover-decoration: underline;

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

// Fonts
$font-size-base: 14px;
$font-weight-base: 400;
$font-family-sans-serif: "OpenSans";
$font-family-base: "Poppins", $font-family-sans-serif, sans-serif;

// Form elements
$form-icon-selected-color: $brand-general;
$form-icon-hover-color: lighten($form-icon-selected-color, 7%);
$input-bg-disabled: $gray-lightest;
$input-group-addon-bg: $gray-lightest;

// Buttons
$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;

// Media queries breakpoints

// NOTE: do not use the media queries variables below. Use the variables/mixins on
// client/app/styleguide/styles/misc/responsive.scss

$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;

$screen-sm-min: $screen-sm;
$screen-xs-max: $screen-sm-min - 1;

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: 1024px;

// Navbar
// NB: Keep in sync with `../resources/variables.js`
$navbar-height: 72px;
$navbar-margin-bottom: 0;
$navbar-default-bg: #fff;
$navbar-default-color: $text-color;
$navbar-default-border: 0;
$navbar-default-link-color: $text-color;

// Inverted navbar
$navbar-inverse-bg: #131313;

// Navs
$nav-link-padding-v: 15px;
$nav-link-padding-h: 20px;
$nav-link-padding: $nav-link-padding-v $nav-link-padding-h;
$nav-link-hover-bg: $color-general;

// Form states and alerts
// NOTE: Keep in sync w/ `styles/resources/colors.js`
$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken($state-success-bg, 5%);

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken($state-info-bg, 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken($state-danger-bg, 5%);

// Modals
$modal-title-padding: 15px;
$modal-inner-padding: 24px;
$modal-inner-padding-mobile: 24px;
$modal-side-panel-padding: 80px;

// Tabs
$nav-tabs-link-hover-border-color: $gray-lightest;

// Pagination
$pagination-hover-bg: #fff;

// Jumbotron
$jumbotron-bg: $gray-lightest;

// List groups
$list-group-disabled-bg: $gray-lightest;

// Panels
$panel-heading-padding-v: 10px;
$panel-heading-padding-h: 15px;
$panel-heading-padding: $panel-heading-padding-v $panel-heading-padding-h;

// Type
$blockquote-border-color: $gray-lightest;
$page-header-border-color: $gray-lightest;
$hr-border: $gray-lightest;

$grid-float-breakpoint: $screen-md;

/*
 * This file contains values, that bootstrap calculates under the hood.
 * So they can't be simply reused or redefined.
 * If such value is required for layout — it can be hardcoded here.
 *
 */

$bs-grid-md-paddings: 15px;

/**
 * @desc This module contains common SCSS variables.
 *       If you need to re-use variable in JS component
 *       duplicate it in `./variables.js` or `Layout.res` module and keep it in sync!
 *
 */

@import "~app/styleguide/styles/bootstrap/pre-customizations";

// Grid
$gap: 16px;
$gap-big: 24px;
$gap-small: 10px;

$layout-container-padding-h-xs: $gap;

// Backgrounds
$background-lightest: $color-neutral-50;
$background-light: $color-neutral-100;
$background-normal: $color-neutral-200;
$background-dark: $color-neutral-700;
$error-background: $color-error-100;
$warning-background: $color-warning-50;
$card-background: #fafafa;
$different-card-background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.8) 100%);

// Borders
$border-light: 1px solid $color-neutral-lighter;
$border: 1px solid $color-general;

// Navbar
$layout-navbar-height: 72px;
$layout-navbar-text-to-icon-spacing: 8px;
$layout-navbar-icon-size: 18px;
$layout-navbar-item-spacing: 20px;
$layout-navbar-line-height: 21px;
$layout-navbar-max-width: 1440px;

$nav-item-padding-h-sm: 6px;
$nav-item-padding-h-md: 7px;

$secondary-navbar-height: 50px;
$mobile-navbar-height: 72px;
$navbar-border-radius: 0;
$navbar-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);

// Sidebar
$layout-sidebar-width: 465px;
$layout-sidebar-mobile-width: 100%;

// Dropdowns
$dropdown-accent-background: #f7f7f7;
$dropdown-full-width-gap: 20px; // iphone auto zooms when you start typing in text field. Make sure the gap is enough to see dropdown text after zooming
$dropdown-border-radius: 8px;
$dropdown-padding: 12px 0;
$dropdown-item-padding: 12px $gap-big;

// Transition defaults
$transition-duration: 0.05s;
$transition-timing-function: ease-in;

// Others
$overlay-darker: rgba(0, 0, 0, 0.5);
$overlay-dark: rgba(0, 0, 0, 0.2);
$color-active-star: $color-primary-400;
$state-color-deviation: 10%;

// Breadcumbs
$color-breadcrumbs: $color-neutral-600;

// Footer
$color-footer-background: $color-neutral-50;

// Relationship Type Colors
// NB: Keep in sync with `./variables.js`
$relationship-membership-color: $color-primary;
$relationship-contact-color: #4d71b9;
$relationship-public-guest-color: #fff;
$relationship-owner-color: $color-primary;

// tile
$tile-max-width: 800px;
$tile-max-width-mobile: 343px;
$listings-wrapper-width: 676px;
$listing-tile-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$listing-tile-hover-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
$listing-tile-map-shadow: 0 5px 15px -3px rgba(0, 0, 0, 0.1);
$listing-tile-container-border-radius: 8px;
$listing-tile-map-container-border-radius: 10px;
$listing-tile-map-card-width: 300px;

// BLV
$tile-picture-width-desktop: 310px;
$tile-picture-height-desktop: 182px;
$tile-picture-width-mobile: 100%;
$tile-picture-height-mobile: 176px;

// SLV
$provider-best-deal: $teal;
$verified-host-best-deal-bg: $color-success-50;
$verified-host-best-deal-border: $color-success-500;
$property-manager-best-deal-bg: $color-property-manager-50;
$property-manager-best-deal-border: $color-property-manager-150;
$ota-best-deal-bg: $color-primary-30;
$ota-best-deal-border: $color-primary-500;

// Welcome Page
$welcome-sm-tile-width: 320px;
$welcome-md-tile-width: 340px;
$welcome-summary-bullets-margin-top: -8px;
$welcome-example-box-side-tile-lg-padding-vertical: 10px;
$welcome-example-box-side-tile-lg-padding-horizontal: 12px;
$welcome-example-box-side-tile-md-padding: 10px;
$welcome-example-box-side-tile-sm-padding: 16px;

// Verification
$verification-navbar-height: 80px;
$verification-wizard-navbar-height: $verification-navbar-height;
$verification-wizard-navbar-height-mobile: 112px;
$verification-container-padding-x-mobile: 16px;
$verification-content-max-width: 544px;

// Datepicker
$datepicker-day-selected: $color-general;
$date-picker-range-selected: $color-neutral-lighter;

// Text
$text-color: $color-general;
$text-additional: $color-neutral-600;
$text-description: $color-neutral;
$text-disabled: $gray-light;

// Links
$link-color: $color-general;
$link-color-primary: $color-primary;
$link-transition-duration: 100ms;

// Inputs
$input-border-radius: 3px;
$placeholder-color: $text-disabled;
$deals-form-border-radius: 48px;
$input-label-color: $color-general;
$outline-color: $color-neutral-dark;

// Modals
$modal-border-radius: 8px;
$modal-content-backgroud-color: $white;

// Feedback popup modal
$feedback-popup-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
$feedback-popup-logo-shadow: 0 2.41372px 2.41372px rgba(0, 0, 0, 0.25);

// Notification
$notification-background: rgba(255, 246, 244, 0.98);

// Sidebar
$collapsed-sidebar-width: 80px;

// Shadows
$shadow-gray: 0 2px 12px 0 rgba(0, 0, 0, 0.24);
$card-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
$card-shadow-hover: 0 2px 12px 0 rgba(0, 0, 0, 0.13);
$inner-card-shadow: 0 0 12px rgba(0, 0, 0, 0.13);
$summary-bullets-shadow: 0 2px 5px rgba(40, 49, 57, 0.16);
$different-card-shadow: 0 0 24px rgba(0, 0, 0, 0.13);
$verification-cards-shadow: 0 5px 15px -3px rgba(0, 0, 0, 0.1), 0 0 6px -4px rgba(0, 0, 0, 0.1);
$legacy-tiles-shadow: 0 0 6px -4px rgba(0, 0, 0, 0.1), 0 5px 15px -3px rgba(0, 0, 0, 0.1);
$image-container-shadow: 0 1px 8px 0 rgba(43, 72, 101, 0.16);
$shadow-xs: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$shadow-md: 0 7px 14px 0 rgba(0, 0, 0, 0.1);

// Transfer Page
$transfer-page-margin: $gap;

// Buttons
$button-border-radius: 100px;

// Controls
$text-to-icon-spacing: 8px;

// tap area cannot be smaller than 48x48px. Otherwise we fail the tap audit on https://pagespeed.web.dev/
$min-tap-size: 48px;

// Banners
$banner-border-radius: 10px;

/* stylelint-disable at-rule-empty-line-before, block-opening-brace-space-before */

@mixin clearfix() {
  &::before,
  &::after {
    display: table;
    content: " ";
  }

  &::after {
    clear: both;
  }
}

@mixin uppercase() {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 0.85em;
}

@mixin limit-to-lines($lineCount) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: $lineCount; /* number of lines to show */
  line-clamp: $lineCount;
  -webkit-box-orient: vertical;
}

@mixin outline($color: $outline-color, $hasOffset: true) {
  outline: 2px solid $color;

  @if $hasOffset {
    outline-offset: 3px;
  } @else {
    outline-offset: -2px;
  }
}

@mixin focus-visible($color: $outline-color, $hasOffset: true) {
  &:focus-visible {
    @include outline($color, $hasOffset);
  }
}

@mixin focus-visible-rounded($color: $outline-color, $hasOffset: true, $hasPadding: true) {
  @if $hasPadding {
    margin-inline: -10px;
    padding-inline: 10px;
  } @else {
    margin-inline: 0;
    padding-inline: 0;
  }

  border-radius: $button-border-radius;

  @include focus-visible($color, $hasOffset);
}

@mixin icon($icon) {
  background-image: url("app/assets/images/icons/#{$icon}.svg");
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%) brightness(500%) brightness(60%); // TODO: ¯\_(ツ)_/¯
  content: "";
}

@mixin bs-icon($icon) {
  font-family: "Glyphicons Halflings";
  @if $icon == "asterisk" {
    content: "\002a";
  }
  @if $icon == "plus" {
    content: "\002b";
  }
  @if $icon == "eur" {
    content: "\20ac";
  }
  @if $icon == "minus" {
    content: "\2212";
  }
  @if $icon == "cloud" {
    content: "\2601";
  }
  @if $icon == "envelope" {
    content: "\2709";
  }
  @if $icon == "pencil" {
    content: "\270f";
  }
  @if $icon == "glass" {
    content: "\e001";
  }
  @if $icon == "music" {
    content: "\e002";
  }
  @if $icon == "search" {
    content: "\e003";
  }
  @if $icon == "heart" {
    content: "\e005";
  }
  @if $icon == "star" {
    content: "\e006";
  }
  @if $icon == "star-empty" {
    content: "\e007";
  }
  @if $icon == "user" {
    content: "\e008";
  }
  @if $icon == "film" {
    content: "\e009";
  }
  @if $icon == "th-large" {
    content: "\e010";
  }
  @if $icon == "th" {
    content: "\e011";
  }
  @if $icon == "th-list" {
    content: "\e012";
  }
  @if $icon == "ok" {
    content: "\e013";
  }
  @if $icon == "remove" {
    content: "\e014";
  }
  @if $icon == "zoom-in" {
    content: "\e015";
  }
  @if $icon == "zoom-out" {
    content: "\e016";
  }
  @if $icon == "off" {
    content: "\e017";
  }
  @if $icon == "signal" {
    content: "\e018";
  }
  @if $icon == "cog" {
    content: "\e019";
  }
  @if $icon == "trash" {
    content: "\e020";
  }
  @if $icon == "home" {
    content: "\e021";
  }
  @if $icon == "file" {
    content: "\e022";
  }
  @if $icon == "time" {
    content: "\e023";
  }
  @if $icon == "road" {
    content: "\e024";
  }
  @if $icon == "download-alt" {
    content: "\e025";
  }
  @if $icon == "download" {
    content: "\e026";
  }
  @if $icon == "upload" {
    content: "\e027";
  }
  @if $icon == "inbox" {
    content: "\e028";
  }
  @if $icon == "play-circle" {
    content: "\e029";
  }
  @if $icon == "repeat" {
    content: "\e030";
  }
  @if $icon == "refresh" {
    content: "\e031";
  }
  @if $icon == "list-alt" {
    content: "\e032";
  }
  @if $icon == "lock" {
    content: "\e033";
  }
  @if $icon == "flag" {
    content: "\e034";
  }
  @if $icon == "headphones" {
    content: "\e035";
  }
  @if $icon == "volume-off" {
    content: "\e036";
  }
  @if $icon == "volume-down" {
    content: "\e037";
  }
  @if $icon == "volume-up" {
    content: "\e038";
  }
  @if $icon == "qrcode" {
    content: "\e039";
  }
  @if $icon == "barcode" {
    content: "\e040";
  }
  @if $icon == "tag" {
    content: "\e041";
  }
  @if $icon == "tags" {
    content: "\e042";
  }
  @if $icon == "book" {
    content: "\e043";
  }
  @if $icon == "bookmark" {
    content: "\e044";
  }
  @if $icon == "print" {
    content: "\e045";
  }
  @if $icon == "camera" {
    content: "\e046";
  }
  @if $icon == "font" {
    content: "\e047";
  }
  @if $icon == "bold" {
    content: "\e048";
  }
  @if $icon == "italic" {
    content: "\e049";
  }
  @if $icon == "text-height" {
    content: "\e050";
  }
  @if $icon == "text-width" {
    content: "\e051";
  }
  @if $icon == "align-left" {
    content: "\e052";
  }
  @if $icon == "align-center" {
    content: "\e053";
  }
  @if $icon == "align-right" {
    content: "\e054";
  }
  @if $icon == "align-justify" {
    content: "\e055";
  }
  @if $icon == "list" {
    content: "\e056";
  }
  @if $icon == "indent-left" {
    content: "\e057";
  }
  @if $icon == "indent-right" {
    content: "\e058";
  }
  @if $icon == "facetime-video" {
    content: "\e059";
  }
  @if $icon == "picture" {
    content: "\e060";
  }
  @if $icon == "map-marker" {
    content: "\e062";
  }
  @if $icon == "adjust" {
    content: "\e063";
  }
  @if $icon == "tint" {
    content: "\e064";
  }
  @if $icon == "edit" {
    content: "\e065";
  }
  @if $icon == "share" {
    content: "\e066";
  }
  @if $icon == "check" {
    content: "\e067";
  }
  @if $icon == "move" {
    content: "\e068";
  }
  @if $icon == "step-backward" {
    content: "\e069";
  }
  @if $icon == "fast-backward" {
    content: "\e070";
  }
  @if $icon == "backward" {
    content: "\e071";
  }
  @if $icon == "play" {
    content: "\e072";
  }
  @if $icon == "pause" {
    content: "\e073";
  }
  @if $icon == "stop" {
    content: "\e074";
  }
  @if $icon == "forward" {
    content: "\e075";
  }
  @if $icon == "fast-forward" {
    content: "\e076";
  }
  @if $icon == "step-forward" {
    content: "\e077";
  }
  @if $icon == "eject" {
    content: "\e078";
  }
  @if $icon == "chevron-left" {
    content: "\e079";
  }
  @if $icon == "chevron-right" {
    content: "\e080";
  }
  @if $icon == "plus-sign" {
    content: "\e081";
  }
  @if $icon == "minus-sign" {
    content: "\e082";
  }
  @if $icon == "remove-sign" {
    content: "\e083";
  }
  @if $icon == "ok-sign" {
    content: "\e084";
  }
  @if $icon == "question-sign" {
    content: "\e085";
  }
  @if $icon == "info-sign" {
    content: "\e086";
  }
  @if $icon == "screenshot" {
    content: "\e087";
  }
  @if $icon == "remove-circle" {
    content: "\e088";
  }
  @if $icon == "ok-circle" {
    content: "\e089";
  }
  @if $icon == "ban-circle" {
    content: "\e090";
  }
  @if $icon == "arrow-left" {
    content: "\e091";
  }
  @if $icon == "arrow-right" {
    content: "\e092";
  }
  @if $icon == "arrow-up" {
    content: "\e093";
  }
  @if $icon == "arrow-down" {
    content: "\e094";
  }
  @if $icon == "share-alt" {
    content: "\e095";
  }
  @if $icon == "resize-full" {
    content: "\e096";
  }
  @if $icon == "resize-small" {
    content: "\e097";
  }
  @if $icon == "exclamation-sign" {
    content: "\e101";
  }
  @if $icon == "gift" {
    content: "\e102";
  }
  @if $icon == "leaf" {
    content: "\e103";
  }
  @if $icon == "fire" {
    content: "\e104";
  }
  @if $icon == "eye-open" {
    content: "\e105";
  }
  @if $icon == "eye-close" {
    content: "\e106";
  }
  @if $icon == "warning-sign" {
    content: "\e107";
  }
  @if $icon == "plane" {
    content: "\e108";
  }
  @if $icon == "calendar" {
    content: "\e109";
  }
  @if $icon == "random" {
    content: "\e110";
  }
  @if $icon == "comment" {
    content: "\e111";
  }
  @if $icon == "magnet" {
    content: "\e112";
  }
  @if $icon == "chevron-up" {
    content: "\e113";
  }
  @if $icon == "chevron-down" {
    content: "\e114";
  }
  @if $icon == "retweet" {
    content: "\e115";
  }
  @if $icon == "shopping-cart" {
    content: "\e116";
  }
  @if $icon == "folder-close" {
    content: "\e117";
  }
  @if $icon == "folder-open" {
    content: "\e118";
  }
  @if $icon == "resize-vertical" {
    content: "\e119";
  }
  @if $icon == "resize-horizontal" {
    content: "\e120";
  }
  @if $icon == "hdd" {
    content: "\e121";
  }
  @if $icon == "bullhorn" {
    content: "\e122";
  }
  @if $icon == "bell" {
    content: "\e123";
  }
  @if $icon == "certificate" {
    content: "\e124";
  }
  @if $icon == "thumbs-up" {
    content: "\e125";
  }
  @if $icon == "thumbs-down" {
    content: "\e126";
  }
  @if $icon == "hand-right" {
    content: "\e127";
  }
  @if $icon == "hand-left" {
    content: "\e128";
  }
  @if $icon == "hand-up" {
    content: "\e129";
  }
  @if $icon == "hand-down" {
    content: "\e130";
  }
  @if $icon == "circle-arrow-right" {
    content: "\e131";
  }
  @if $icon == "circle-arrow-left" {
    content: "\e132";
  }
  @if $icon == "circle-arrow-up" {
    content: "\e133";
  }
  @if $icon == "circle-arrow-down" {
    content: "\e134";
  }
  @if $icon == "globe" {
    content: "\e135";
  }
  @if $icon == "wrench" {
    content: "\e136";
  }
  @if $icon == "tasks" {
    content: "\e137";
  }
  @if $icon == "filter" {
    content: "\e138";
  }
  @if $icon == "briefcase" {
    content: "\e139";
  }
  @if $icon == "fullscreen" {
    content: "\e140";
  }
  @if $icon == "dashboard" {
    content: "\e141";
  }
  @if $icon == "paperclip" {
    content: "\e142";
  }
  @if $icon == "heart-empty" {
    content: "\e143";
  }
  @if $icon == "link" {
    content: "\e144";
  }
  @if $icon == "phone" {
    content: "\e145";
  }
  @if $icon == "pushpin" {
    content: "\e146";
  }
  @if $icon == "usd" {
    content: "\e148";
  }
  @if $icon == "gbp" {
    content: "\e149";
  }
  @if $icon == "sort" {
    content: "\e150";
  }
  @if $icon == "sort-by-alphabet" {
    content: "\e151";
  }
  @if $icon == "sort-by-alphabet-alt" {
    content: "\e152";
  }
  @if $icon == "sort-by-order" {
    content: "\e153";
  }
  @if $icon == "sort-by-order-alt" {
    content: "\e154";
  }
  @if $icon == "sort-by-attributes" {
    content: "\e155";
  }
  @if $icon == "sort-by-attributes-alt" {
    content: "\e156";
  }
  @if $icon == "unchecked" {
    content: "\e157";
  }
  @if $icon == "expand" {
    content: "\e158";
  }
  @if $icon == "collapse-down" {
    content: "\e159";
  }
  @if $icon == "collapse-up" {
    content: "\e160";
  }
  @if $icon == "log-in" {
    content: "\e161";
  }
  @if $icon == "flash" {
    content: "\e162";
  }
  @if $icon == "log-out" {
    content: "\e163";
  }
  @if $icon == "new-window" {
    content: "\e164";
  }
  @if $icon == "record" {
    content: "\e165";
  }
  @if $icon == "save" {
    content: "\e166";
  }
  @if $icon == "open" {
    content: "\e167";
  }
  @if $icon == "saved" {
    content: "\e168";
  }
  @if $icon == "import" {
    content: "\e169";
  }
  @if $icon == "export" {
    content: "\e170";
  }
  @if $icon == "send" {
    content: "\e171";
  }
  @if $icon == "floppy-disk" {
    content: "\e172";
  }
  @if $icon == "floppy-saved" {
    content: "\e173";
  }
  @if $icon == "floppy-remove" {
    content: "\e174";
  }
  @if $icon == "floppy-save" {
    content: "\e175";
  }
  @if $icon == "floppy-open" {
    content: "\e176";
  }
  @if $icon == "credit-card" {
    content: "\e177";
  }
  @if $icon == "transfer" {
    content: "\e178";
  }
  @if $icon == "cutlery" {
    content: "\e179";
  }
  @if $icon == "header" {
    content: "\e180";
  }
  @if $icon == "compressed" {
    content: "\e181";
  }
  @if $icon == "earphone" {
    content: "\e182";
  }
  @if $icon == "phone-alt" {
    content: "\e183";
  }
  @if $icon == "tower" {
    content: "\e184";
  }
  @if $icon == "stats" {
    content: "\e185";
  }
  @if $icon == "sd-video" {
    content: "\e186";
  }
  @if $icon == "hd-video" {
    content: "\e187";
  }
  @if $icon == "subtitles" {
    content: "\e188";
  }
  @if $icon == "sound-stereo" {
    content: "\e189";
  }
  @if $icon == "sound-dolby" {
    content: "\e190";
  }
  @if $icon == "sound-5-1" {
    content: "\e191";
  }
  @if $icon == "sound-6-1" {
    content: "\e192";
  }
  @if $icon == "sound-7-1" {
    content: "\e193";
  }
  @if $icon == "copyright-mark" {
    content: "\e194";
  }
  @if $icon == "registration-mark" {
    content: "\e195";
  }
  @if $icon == "cloud-download" {
    content: "\e197";
  }
  @if $icon == "cloud-upload" {
    content: "\e198";
  }
  @if $icon == "tree-conifer" {
    content: "\e199";
  }
  @if $icon == "tree-deciduous" {
    content: "\e200";
  }
  @if $icon == "cd" {
    content: "\e201";
  }
  @if $icon == "save-file" {
    content: "\e202";
  }
  @if $icon == "open-file" {
    content: "\e203";
  }
  @if $icon == "level-up" {
    content: "\e204";
  }
  @if $icon == "copy" {
    content: "\e205";
  }
  @if $icon == "paste" {
    content: "\e206";
  }
  @if $icon == "alert" {
    content: "\e209";
  }
  @if $icon == "equalizer" {
    content: "\e210";
  }
  @if $icon == "king" {
    content: "\e211";
  }
  @if $icon == "queen" {
    content: "\e212";
  }
  @if $icon == "pawn" {
    content: "\e213";
  }
  @if $icon == "bishop" {
    content: "\e214";
  }
  @if $icon == "knight" {
    content: "\e215";
  }
  @if $icon == "baby-formula" {
    content: "\e216";
  }
  @if $icon == "tent" {
    content: "\26fa";
  }
  @if $icon == "blackboard" {
    content: "\e218";
  }
  @if $icon == "bed" {
    content: "\e219";
  }
  @if $icon == "apple" {
    content: "\f8ff";
  }
  @if $icon == "erase" {
    content: "\e221";
  }
  @if $icon == "hourglass" {
    content: "\231b";
  }
  @if $icon == "lamp" {
    content: "\e223";
  }
  @if $icon == "duplicate" {
    content: "\e224";
  }
  @if $icon == "piggy-bank" {
    content: "\e225";
  }
  @if $icon == "scissors" {
    content: "\e226";
  }
  @if $icon == "bitcoin" {
    content: "\e227";
  }
  @if $icon == "btc" {
    content: "\e227";
  }
  @if $icon == "xbt" {
    content: "\e227";
  }
  @if $icon == "yen" {
    content: "\00a5";
  }
  @if $icon == "jpy" {
    content: "\00a5";
  }
  @if $icon == "ruble" {
    content: "\20bd";
  }
  @if $icon == "rub" {
    content: "\20bd";
  }
  @if $icon == "scale" {
    content: "\e230";
  }
  @if $icon == "ice-lolly" {
    content: "\e231";
  }
  @if $icon == "ice-lolly-tasted" {
    content: "\e232";
  }
  @if $icon == "education" {
    content: "\e233";
  }
  @if $icon == "option-horizontal" {
    content: "\e234";
  }
  @if $icon == "option-vertical" {
    content: "\e235";
  }
  @if $icon == "menu-hamburger" {
    content: "\e236";
  }
  @if $icon == "modal-window" {
    content: "\e237";
  }
  @if $icon == "oil" {
    content: "\e238";
  }
  @if $icon == "grain" {
    content: "\e239";
  }
  @if $icon == "sunglasses" {
    content: "\e240";
  }
  @if $icon == "text-size" {
    content: "\e241";
  }
  @if $icon == "text-color" {
    content: "\e242";
  }
  @if $icon == "text-background" {
    content: "\e243";
  }
  @if $icon == "object-align-top" {
    content: "\e244";
  }
  @if $icon == "object-align-bottom" {
    content: "\e245";
  }
  @if $icon == "object-align-horizontal" {
    content: "\e246";
  }
  @if $icon == "object-align-left" {
    content: "\e247";
  }
  @if $icon == "object-align-vertical" {
    content: "\e248";
  }
  @if $icon == "object-align-right" {
    content: "\e249";
  }
  @if $icon == "triangle-right" {
    content: "\e250";
  }
  @if $icon == "triangle-left" {
    content: "\e251";
  }
  @if $icon == "triangle-bottom" {
    content: "\e252";
  }
  @if $icon == "triangle-top" {
    content: "\e253";
  }
  @if $icon == "console" {
    content: "\e254";
  }
  @if $icon == "superscript" {
    content: "\e255";
  }
  @if $icon == "subscript" {
    content: "\e256";
  }
  @if $icon == "menu-left" {
    content: "\e257";
  }
  @if $icon == "menu-right" {
    content: "\e258";
  }
  @if $icon == "menu-down" {
    content: "\e259";
  }
  @if $icon == "menu-up" {
    content: "\e260";
  }
}

@mixin arrow($size, $color) {
  display: block;
  position: absolute;
  left: 50%;
  bottom: -$size;
  margin-left: -$size;
  border-width: $size;
  border-bottom-width: 0 !important;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
}

@mixin arrow-after($size, $color) {
  display: block;
  position: absolute;
  bottom: 2px;
  margin-left: -($size - 1);
  border-width: $size - 1;
  border-bottom-width: 0 !important;
  border-style: solid;
  border-color: transparent;
  border-top-color: $color;
  width: 0;
  height: 0;
  content: " ";
}

@mixin arrow-with-shadow($arrow-size, $border-color, $arrow-color) {
  @include arrow($arrow-size, $border-color);

  &::after {
    @include arrow-after($arrow-size, $arrow-color);
  }
}

@mixin listing-results-map-marker($border-color) {
  position: absolute;
  padding: 2px 4px;
  box-shadow: 0 0 0 1px $border-color;
  border-radius: 2px;
}

@mixin container-sized() {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@mixin container-wide {
  width: 100%;
  margin: 0 auto;
  padding: 0 $layout-container-padding-h-xs;
  max-width: 2000px;

  @include md {
    padding: 0 35px;
  }

  @include xl {
    padding: 0 8%;
  }
}

@mixin container-centered {
  @include container-wide();

  max-width: 1330px;
}

@mixin container-wide-banner {
  margin: 0 -#{$layout-container-padding-h-xs};

  @include md {
    margin: 0 -35px;
  }

  @include xl {
    margin: 0 -4%;
  }
}

// shift content so navbar doesn't overlap it
@mixin main-container() {
  padding-top: $mobile-navbar-height;

  @include md {
    padding-top: $layout-navbar-height;
  }
}

// stretch main banner under the navbar so the navbar can be transparent
@mixin main-banner() {
  margin-top: -$layout-navbar-height;
  padding-top: $layout-navbar-height;
}

@mixin triangle($direction, $width, $height, $color) {
  // TODO: Add `top-left`, `top-right` etc.
  @if ($direction == "up") {
    transform: rotate(135deg);
  } @else if ($direction == "down") {
    transform: rotate(-45deg);
  } @else if ($direction == "left") {
    transform: rotate(45deg);
  } @else if ($direction == "right") {
    transform: rotate(-135deg);
  }

  transform-origin: 0 0;
  border-width: $height calc($width / 2) 0;
  border-style: solid;
  border-color: transparent transparent $color $color;
  background-color: $color;
  width: 0;
  height: 0;
  content: "";
}

@mixin dropdown-full-width($gap: $dropdown-full-width-gap) {
  left: 50%;
  width: calc(100vw - #{$gap} * 2);
  margin-left: calc(-50vw + #{$gap});
  overflow-y: auto;
}

// screen readers are still able to read HTML elements with this css
@mixin visuallyhidden {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// Animations

@mixin pulse-animation($duration, $color-name, $color-value) {
  $name: animation-pulse-#{$color-name};

  @keyframes #{$name} {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba($color-value, 0.4);
      box-shadow: 0 0 0 0 rgba($color-value, 0.4);
    }

    70% {
      -moz-box-shadow: 0 0 0 10px rgba($color-value, 0);
      box-shadow: 0 0 0 10px rgba($color-value, 0);
    }

    100% {
      -moz-box-shadow: 0 0 0 0 rgba($color-value, 0);
      box-shadow: 0 0 0 0 rgba($color-value, 0);
    }
  }

  @-webkit-keyframes #{$name} {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba($color-value, 0.4);
    }

    70% {
      -webkit-box-shadow: 0 0 0 10px rgba($color-value, 0);
    }

    100% {
      -webkit-box-shadow: 0 0 0 0 rgba($color-value, 0);
    }
  }

  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: infinite;
}

@mixin fade-in-bottom-animation {
  @keyframes fade-in-bottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  animation: fade-in-bottom 0.5s ease-out forwards;
}

@mixin deal-row($bg-color, $border-color) {
  background-color: $bg-color;
  border: 1px solid $border-color;
  border-radius: $slv-block-border-radius;
}

$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

$small-mobile-screen: 360px;
$medium-mobile-screen: 400px;
$tablet-for-tiles: 570px;

@mixin sm {
  @media (min-width: $screen-sm) {
    @content;
  }
}

@mixin md {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin xl {
  @media (min-width: $screen-xl) {
    @content;
  }
}

// outdated responsive mixins. Use mobile-first approach
@mixin mobile-legacy {
  @media (max-width: $screen-md - 1) {
    @content;
  }
}

@mixin tablet-legacy {
  @media (min-width: $screen-md) and (max-width: $screen-lg - 1) {
    @content;
  }
}

@mixin small-desktop-legacy {
  @media (min-width: $screen-lg) and (max-width: $screen-xl - 1) {
    @content;
  }
}

@mixin mobile-or-tablet-legacy {
  @media (max-width: $screen-lg - 1) {
    @content;
  }
}

// we want phones in landscape to show blv and homepage tiles in tablet view (horizontal tiles)
@mixin tile-mobile-legacy {
  @media (max-width: $tablet-for-tiles - 1) {
    @content;
  }
}

@mixin tile-tablet-legacy {
  @media (min-width: $tablet-for-tiles) and (max-width: $screen-lg - 1) {
    @content;
  }
}

@mixin small-mobile-legacy {
  @media (max-width: $small-mobile-screen - 1) {
    @content;
  }
}

@mixin medium-mobile-legacy {
  @media (max-width: $medium-mobile-screen - 1) {
    @content;
  }
}

// Take into account that Bootstrap@3's Tooltips and Popovers z-indexes
// are between 1000 and 2000
// E.g. Tooltip's z-index = 1070
// https://github.com/twbs/bootstrap/blob/5359cb805/less/tooltip.less#L9
// https://github.com/twbs/bootstrap/blob/98b9ff6b7ad3f/less/variables.less#L275

// adding just 1 to make something over another thing makes it harder to insert
// something in between later
$z-index-preferred-step: 10;

// Global
$z-index-confirm-modal: 10000;

// all modals should appear on top of the photoswipe gallery
$z-index-modal: 10400; // this is actualy set via bootstrap
$z-index-tooltip: 1100;
$z-index-notification: 1100;
$z-index-modal-close-icon: $z-index-modal + 1;
$z-index-modal-spinner: $z-index-modal + 2;
$z-index-toast: $z-index-modal + 3;
$z-index-google-pac-container: 20000;
$z-index-file-uploader-overlay: 11000;
$z-index-site-navbar: 999;
$z-index-side-nav: 1000;
$z-index-side-nav-backdrop: $z-index-site-navbar + 1;
$z-index-side-nav-backdrop-mobile-or-tablet: 1002;
$z-index-not-found-page: 997;
$z-index-data-table-action-bar: 1;
$z-index-impersonate-footer: 50;

// Listing Wizard
$z-index-listing-wizard-confirm-reset: 100;
$z-index-listing-wizard-creating-overlay: 50000;

// Listings Index
$z-index-search-control: 1;

// Listing
$z-index-listing-navbar: 90;
$z-index-listing-loading-overlay: 91;

// LEM/SLV widgets
$z-index-listing-widget-fixed: 100; // MUST be greater than $z-index-listing-navbar
$z-index-listing-show-listing-urls-icon: 10;

// b/c we render modal dialogs as children of widget

// Listing Calendar
$z-index-listing-calendar-reservation-form-date-input: 7;
$z-index-listing-calendar-reservation-form-submission-overlays: 8;

// SLV
$z-index-listing-url-field: 10;

$z-index-pick-dates-overlay: 10;
$z-index-listing-show-photo-gallery-overlay: 10000;
$z-index-listing-show-photo-gallery-ui: 10100;
$z-index-listing-show-photo-gallery-share-ui: 10200;
$z-index-listing-show-photo-gallery-share-tooltip: 10250;
$z-index-listing-show-photo-gallery-hero-icons: 99; // MUST be greater than $z-index-listings-index-results-prev-next
$z-index-listing-show-book-button-fixed: 5;
$z-index-listing-show-hero-gallery: 5;
$z-index-listing-show-mobile-booking-footer: 100;

// BLV
$z-index-listings-index-search: 1;
$z-index-listings-index-results-prev-next: 20;
$z-index-listings-index-price-overlay: 30;
$z-index-listings-index-social-indicators: 30;
$z-index-listings-filter-container: 101;
$z-index-listings-index-dropdown-body: 102;
$z-index-listings-index-results-overlay: 300;
$z-index-listings-guests-dropdown: 1000;
$z-index-listings-index-results-map: 1000;
$z-index-listings-map-toggle-button: 1000;
$z-index-listings-index-search-with-map-open-mobile: 1000;
$z-index-listings-page-filters-opened: 2000;
$z-index-pac-container: 2001;
$z-index-dates-selection: 102; // MUST be grater than "favorites count" icons z-index = 101

// tile
$z-index-listing-tile-on-map: 1000;

// GLM
$z-index-glm-manage-menu: 100;

/**
 *  Listing Results Map
 */
// make the hover result marker be on top of any other marker
$z-index-listing-result-map-marker-hover: 500;

// make the listing result overlay be on top of everything on the map
$z-index-listing-result-map-overlay: 1000;

// Welcome Page
$z-index-welcome-hero-background-image: 1;
$z-index-welcome-hero-background-overlay: 2;
$z-index-welcome-summary-bullets: 3;
$z-index-welcome-hero: 4;
$z-index-welcome-day-picker: $z-index-welcome-summary-bullets + $z-index-preferred-step;

// Progressive Image
$z-index-progressive-blured-image: 1;
$z-index-progressive-normal-image: 3;

// Top Tips
$z-index-top-tips-hero: 10;

// Listings Dropdown
$z-index-listings-dropdown: 1000;

// Hosts page
$z-index-profile-dropdown-body: 102;

$z-index-navbar: 1010;
$z-index-listings-results-overlay: 200;

@import "~app/styleguide/styles/colors/index";
@import "~app/styleguide/styles/misc/variables";
@import "~app/styleguide/styles/bootstrap/pre-customizations";

.button {
  display: inline-flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: center;
  justify-content: center;
  transition-property: box-shadow, background-color, border-color;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  cursor: pointer;
  border: none;
  font-family: $font-family-base;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border-radius: $button-border-radius;
  gap: 8px;
  outline: none;

  &:hover {
    text-decoration: none;
  }

  @include focus-visible();

  &:disabled {
    pointer-events: none !important;
  }

  &.busy {
    color: transparent !important;
    pointer-events: none !important;
    user-select: none;
  }
}

/* Styles */
.wide {
  padding-left: 50px;
  padding-right: 50px;
}

.expanded {
  flex: 1;
  width: 100%;
}

/* Colors */
.colorPrimary {
  background-color: $color-primary;
  border: 1px solid $color-primary;
  color: $white;

  &:hover,
  &:active,
  // focus state is used for buttons as native links
  &:focus {
    color: $white;
  }

  &:hover {
    background-color: $color-primary-hover;
    border-color: $color-primary-hover;
  }

  &:active {
    background-color: $color-primary-active;
    border-color: $color-primary-active;
  }

  &:disabled {
    background-color: $color-primary-300;
    border-color: $color-primary-300;
    color: $white;
  }

  &.visuallyDisabled {
    background-color: $background-light;
    border-color: $background-light;
    cursor: not-allowed;
    color: $color-neutral-light;

    g,
    path,
    polygon,
    rect {
      fill: $color-neutral-light !important;
    }

    &.busy {
      color: transparent;
      background-color: $color-primary-300;
      border-color: $color-primary-300;
    }
  }
}

.colorSecondary {
  border: 1px solid $color-primary;
  background-color: $white;
  color: $color-primary;

  // focus state is used for buttons as native links
  &:focus {
    color: $color-primary;
  }

  &:hover {
    background-color: $color-primary-lightest;
  }

  &:active {
    background-color: $color-primary-lighter;
  }

  &:disabled {
    background-color: $white;
    border-color: $color-primary-300;
    color: $color-primary-300;
  }

  &.visuallyDisabled {
    border-color: $color-neutral-light;
    color: $color-neutral-light;
    cursor: not-allowed;

    g,
    path,
    polygon,
    rect {
      fill: $color-neutral-light !important;
    }

    &.busy {
      color: transparent;
      border-color: $color-primary-300;
    }
  }
}

.colorTertiary {
  border: 1px solid $color-general;
  background-color: $white;
  color: $text-color;

  // focus state is used for buttons as native links
  &:focus {
    color: $text-color;
  }

  &:hover {
    background-color: $background-lightest;
    color: $text-color;
  }

  &:active {
    background-color: $background-light;
    color: $text-color;
  }

  &:disabled {
    background-color: $white;
    border-color: $color-neutral-light;
    color: $color-neutral-light;
  }

  &.visuallyDisabled {
    border-color: $color-neutral-light;
    color: $color-neutral-light;
    cursor: not-allowed;

    g,
    path,
    polygon,
    rect {
      fill: $color-neutral-light !important;
    }

    &.busy {
      color: transparent;
    }
  }
}

.colorWhite {
  background-color: transparent;
  border: 1px solid $white;
  color: $white;

  // focus state is used for buttons as native links
  &:focus {
    color: $white;
  }

  &:hover,
  &:active {
    border-color: transparentize($white, 0.2);
    color: $white;
  }

  &.visuallyDisabled {
    border-color: $color-neutral-light;
    color: $color-neutral-light;
    cursor: not-allowed;
  }
}

.colorBlack {
  border: 1px solid $color-general;
  border-color: $color-general;
  background-color: $white;
  color: $color-general;

  // focus state is used for buttons as native links
  &:focus {
    color: $color-general;
  }

  &:hover,
  &:active {
    background-color: $color-general;
    color: $white;
  }

  &.visuallyDisabled {
    background-color: $white;
    border-color: $color-neutral-light;
    color: $color-neutral-light;
    cursor: not-allowed;
  }
}

/* Sizes */
.sizeSm {
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;

  @include lg {
    &.marginLeft {
      margin-left: 8px;
    }

    &.marginRight {
      margin-right: 8px;
    }
  }
}

.sizeMd {
  padding: 9px 19px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;

  @include lg {
    &.marginLeft {
      margin-left: 8px;
    }

    &.marginRight {
      margin-right: 8px;
    }
  }
}

/* Styles */
.wide {
  padding-left: 50px;
  padding-right: 50px;
}

.expanded {
  flex: 1;
  width: 100%;
}
