$input-border-color: $gray-lighter;
$input-border-radius: 4px;
$placeholder-color: $gray-lighter;

$input-sm-padding: 10px;
$input-md-padding: 12px;
$input-lg-padding: 14px;

$input-sm-icon-size: 18px;
$input-md-icon-size: 18px;
$input-lg-icon-size: 18px;
