/**
 * @desc This module contains common SCSS variables.
 *       If you need to re-use variable in JS component
 *       duplicate it in `./variables.js` or `Layout.res` module and keep it in sync!
 *
 */

@import "~app/styleguide/styles/bootstrap/pre-customizations";

// Grid
$gap: 16px;
$gap-big: 24px;
$gap-small: 10px;

$layout-container-padding-h-xs: $gap;

// Backgrounds
$background-lightest: $color-neutral-50;
$background-light: $color-neutral-100;
$background-normal: $color-neutral-200;
$background-dark: $color-neutral-700;
$error-background: $color-error-100;
$warning-background: $color-warning-50;
$card-background: #fafafa;
$different-card-background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.8) 100%);

// Borders
$border-light: 1px solid $color-neutral-lighter;
$border: 1px solid $color-general;

// Navbar
$layout-navbar-height: 72px;
$layout-navbar-text-to-icon-spacing: 8px;
$layout-navbar-icon-size: 18px;
$layout-navbar-item-spacing: 20px;
$layout-navbar-line-height: 21px;
$layout-navbar-max-width: 1440px;

$nav-item-padding-h-sm: 6px;
$nav-item-padding-h-md: 7px;

$secondary-navbar-height: 50px;
$mobile-navbar-height: 72px;
$navbar-border-radius: 0;
$navbar-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);

// Sidebar
$layout-sidebar-width: 465px;
$layout-sidebar-mobile-width: 100%;

// Dropdowns
$dropdown-accent-background: #f7f7f7;
$dropdown-full-width-gap: 20px; // iphone auto zooms when you start typing in text field. Make sure the gap is enough to see dropdown text after zooming
$dropdown-border-radius: 8px;
$dropdown-padding: 12px 0;
$dropdown-item-padding: 12px $gap-big;

// Transition defaults
$transition-duration: 0.05s;
$transition-timing-function: ease-in;

// Others
$overlay-darker: rgba(0, 0, 0, 0.5);
$overlay-dark: rgba(0, 0, 0, 0.2);
$color-active-star: $color-primary-400;
$state-color-deviation: 10%;

// Breadcumbs
$color-breadcrumbs: $color-neutral-600;

// Footer
$color-footer-background: $color-neutral-50;

// Relationship Type Colors
// NB: Keep in sync with `./variables.js`
$relationship-membership-color: $color-primary;
$relationship-contact-color: #4d71b9;
$relationship-public-guest-color: #fff;
$relationship-owner-color: $color-primary;

// tile
$tile-max-width: 800px;
$tile-max-width-mobile: 343px;
$listings-wrapper-width: 676px;
$listing-tile-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$listing-tile-hover-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
$listing-tile-map-shadow: 0 5px 15px -3px rgba(0, 0, 0, 0.1);
$listing-tile-container-border-radius: 8px;
$listing-tile-map-container-border-radius: 10px;
$listing-tile-map-card-width: 300px;

// BLV
$tile-picture-width-desktop: 310px;
$tile-picture-height-desktop: 182px;
$tile-picture-width-mobile: 100%;
$tile-picture-height-mobile: 176px;

// SLV
$provider-best-deal: $teal;
$verified-host-best-deal-bg: $color-success-50;
$verified-host-best-deal-border: $color-success-500;
$property-manager-best-deal-bg: $color-property-manager-50;
$property-manager-best-deal-border: $color-property-manager-150;
$ota-best-deal-bg: $color-primary-30;
$ota-best-deal-border: $color-primary-500;

// Welcome Page
$welcome-sm-tile-width: 320px;
$welcome-md-tile-width: 340px;
$welcome-summary-bullets-margin-top: -8px;
$welcome-example-box-side-tile-lg-padding-vertical: 10px;
$welcome-example-box-side-tile-lg-padding-horizontal: 12px;
$welcome-example-box-side-tile-md-padding: 10px;
$welcome-example-box-side-tile-sm-padding: 16px;

// Verification
$verification-navbar-height: 80px;
$verification-wizard-navbar-height: $verification-navbar-height;
$verification-wizard-navbar-height-mobile: 112px;
$verification-container-padding-x-mobile: 16px;
$verification-content-max-width: 544px;

// Datepicker
$datepicker-day-selected: $color-general;
$date-picker-range-selected: $color-neutral-lighter;

// Text
$text-color: $color-general;
$text-additional: $color-neutral-600;
$text-description: $color-neutral;
$text-disabled: $gray-light;

// Links
$link-color: $color-general;
$link-color-primary: $color-primary;
$link-transition-duration: 100ms;

// Inputs
$input-border-radius: 3px;
$placeholder-color: $text-disabled;
$deals-form-border-radius: 48px;
$input-label-color: $color-general;
$outline-color: $color-neutral-dark;

// Modals
$modal-border-radius: 8px;
$modal-content-backgroud-color: $white;

// Feedback popup modal
$feedback-popup-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
$feedback-popup-logo-shadow: 0 2.41372px 2.41372px rgba(0, 0, 0, 0.25);

// Notification
$notification-background: rgba(255, 246, 244, 0.98);

// Sidebar
$collapsed-sidebar-width: 80px;

// Shadows
$shadow-gray: 0 2px 12px 0 rgba(0, 0, 0, 0.24);
$card-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
$card-shadow-hover: 0 2px 12px 0 rgba(0, 0, 0, 0.13);
$inner-card-shadow: 0 0 12px rgba(0, 0, 0, 0.13);
$summary-bullets-shadow: 0 2px 5px rgba(40, 49, 57, 0.16);
$different-card-shadow: 0 0 24px rgba(0, 0, 0, 0.13);
$verification-cards-shadow: 0 5px 15px -3px rgba(0, 0, 0, 0.1), 0 0 6px -4px rgba(0, 0, 0, 0.1);
$legacy-tiles-shadow: 0 0 6px -4px rgba(0, 0, 0, 0.1), 0 5px 15px -3px rgba(0, 0, 0, 0.1);
$image-container-shadow: 0 1px 8px 0 rgba(43, 72, 101, 0.16);
$shadow-xs: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
$shadow-md: 0 7px 14px 0 rgba(0, 0, 0, 0.1);

// Transfer Page
$transfer-page-margin: $gap;

// Buttons
$button-border-radius: 100px;

// Controls
$text-to-icon-spacing: 8px;

// tap area cannot be smaller than 48x48px. Otherwise we fail the tap audit on https://pagespeed.web.dev/
$min-tap-size: 48px;

// Banners
$banner-border-radius: 10px;
