$input-border-color: $color-neutral-300;
$input-border-radius: 4px;
$placeholder-color: $color-neutral-300;

$input-sm-padding: 10px;
$input-md-padding: 12px;
$input-lg-padding: 14px;

$input-sm-icon-size: 18px;
$input-md-icon-size: 18px;
$input-lg-icon-size: 18px;
